import { Button, Form, Input, Modal, ModalSize, Select, TextArea } from "@kaladel/react/Components";
import { useValidator } from "@kaladel/react/Hooks";
import { HttpStatusCode, Severity } from "@kaladel/react/Utilities";
import { AddressPost, SwaggerResponse } from "ApiClient/ApiClient";
import { useApp } from "Context/Resources";
import { useStaticData } from "Pages/Sites/StaticDataContext";
import * as React from "react";

interface AddressNewModalProps {
    onPost: (model: AddressPost) => Promise<SwaggerResponse<string>>;
    onClose: (dirty: boolean) => void;
}

export const AddressNewModal: React.FC<AddressNewModalProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();
    const { countries } = useStaticData()!;

    const [addressFreeText, setAddressFreeText] = React.useState<string>("");
    const [addressCity, setAddressCity] = React.useState<string>("");
    const [addressState, setAddressState] = React.useState<string>("");
    const [addressPostCode, setAddressPostCode] = React.useState<string>("");
    const [addressCountryId, setAddressCountryId] = React.useState<string | undefined>(undefined);

    const handleSaveClicked = async () => {
        if (!validator.canSubmit()) return;

        const model: AddressPost = {
            addressFreeText,
            city: addressCity,
            state: addressState,
            postCode: addressPostCode,
            countryId: !!addressCountryId ? parseInt(addressCountryId) : null
        };

        const response = await props.onPost(model);

        if (response.status === HttpStatusCode.Http201Created) {
            props.onClose(true);
        }
    };

    return (
        <Modal
            onClose={props.onClose}
            title={resource("Addresses-NewAddress")}
            size={ModalSize.Medium}
        >
            <Form>

                <TextArea
                    id="addressFreeText"
                    label={resource("Addresses-Address-FreeText")}
                    value={addressFreeText}
                    onChange={(e) => setAddressFreeText(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                    rows={3}
                />

                <Input
                    id="addressCity"
                    label={resource("Addresses-Address-City")}
                    value={addressCity}
                    onChange={(e) => setAddressCity(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                />

                <Input
                    id="addressState"
                    label={resource("Addresses-Address-State")}
                    value={addressState}
                    onChange={(e) => setAddressState(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                />

                <Input
                    id="addressPostCode"
                    label={resource("Addresses-Address-PostCode")}
                    value={addressPostCode}
                    onChange={(e) => setAddressPostCode(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                />

                <Select
                    id="addressCountryId"
                    label={resource("Addresses-Address-Country")}
                    value={addressCountryId}
                    onChange={(e) => setAddressCountryId(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                    items={countries}
                    values={c => c.id.toString()}
                    descriptions={c => resource(`Country-${c.id}`)}
                />

                <div className="buttons right">
                    <div>
                        <Button
                            severity={Severity.Success}
                            onClick={() => handleSaveClicked()}
                            type="submit"
                        >
                            {resource("Button-Save")}
                        </Button>
                        <Button
                            severity={Severity.Primary}
                            onClick={() => props.onClose(false)}
                            type="button"
                        >
                            {resource("Button-Cancel")}
                        </Button>
                    </div>
                </div>

            </Form>
        </Modal>
    );
};