import { useValidator } from "@kaladel/react/Hooks";
import * as React from "react";
import { Severity } from "@kaladel/react/Utilities";
import { Button, Input, RadioCheck, Select, TextArea } from "@kaladel/react/Components";

const items: { id: string, name: string }[] = [
    { id: "1", name: "Item 1" },
    { id: "2", name: "Item 2" },
    { id: "3", name: "Item 3" },
    { id: "4", name: "Item 4" }
];

export const Inputs: React.FC = () => {
    const validator = useValidator();

    const handleNewClicked = () => {
        validator.canSubmit();
    };

    const [text, setText] = React.useState<string>("");
    const [date, setDate] = React.useState<string>("2023-11-07");
    const [time, setTime] = React.useState<string>("16:00");
    const [select, setSelect] = React.useState<string | undefined>(undefined);
    const [selectOptional, setSelectOptional] = React.useState<string | undefined>(undefined);
    const [checks, setChecks] = React.useState<string[]>([]);
    const [radios, setRadios] = React.useState<string[]>([]);
    const [textArea, setTextArea] = React.useState<string>("");
    const [expandingTextArea, setExpandingTextArea] = React.useState<string>("");

    return (
        <>
            <Input
                id="text"
                validator={validator}
                validations={{ required: true, minLength: 5, maxLength: 10 }}
                type="text"
                label="Text Input"
                value={text}
                onChange={(e) => setText(e.target.value)}
            />

            <Input
                id="date"
                validator={validator}
                validations={{ required: true }}
                type="date"
                label="Date Input"
                value={date}
                onChange={(e) => setDate(e.target.value)}
            />

            <Input
                id="time"
                validator={validator}
                validations={{ required: true }}
                type="time"
                label="Time Input"
                value={time}
                onChange={(e) => setTime(e.target.value)}
            />

            <Select
                id="selectRequired"
                validator={validator}
                validations={{ required: true }}
                label="Select Input (Required)"
                items={items}
                values={(item) => item.id}
                descriptions={(item) => item.name}
                value={select}
                onChange={(e) => setSelect(e.target.value)}
            />

            <Select
                id="selectOptional"
                validator={validator}
                label="Select Input (Optional)"
                noneOption="(none)"
                items={items}
                values={(item) => item.id}
                descriptions={(item) => item.name}
                value={selectOptional}
                onChange={(e) => setSelectOptional(e.target.value)}
            />

            <RadioCheck
                id="checkboxes"
                multiple={true}
                validator={validator}
                validations={{ minItems: 1, maxItems: 3 }}
                label="Radio Input"
                items={items}
                values={(item) => item.id}
                descriptions={(item) => item.name}
                value={checks}
                onChange={(value) => setChecks(value)}
            />

            <RadioCheck
                id="radios"
                multiple={false}
                validator={validator}
                validations={{ minItems: 1, maxItems: 1 }}
                label="Radio Input"
                items={items}
                values={(item) => item.id}
                descriptions={(item) => item.name}
                value={radios}
                onChange={(value) => setRadios(value)}
            />

            <TextArea
                id="textarea"
                validator={validator}
                validations={{ required: true }}
                label="Text Area Input"
                value={textArea}
                onChange={(e) => setTextArea(e.target.value)}
            />

            <TextArea
                id="expandingTextarea"
                validator={validator}
                validations={{ required: true }}
                label="Expanding Text Area Input"
                grow={true}
                value={expandingTextArea}
                onChange={(e) => setExpandingTextArea(e.target.value)}
            />

            <Button
                onClick={() => handleNewClicked()}
                severity={Severity.Success}
            >
                Test Submit
            </Button>
        </>
    );
};