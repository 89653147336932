import * as React from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Star.module.scss";
import { classNames } from "@kaladel/react/Utilities";

interface StarProps {
    total: number;
    filled?: number;
    title?: string;
    onClick?: () => void;
}

export const Star: React.FC<StarProps> = (props) => {
    const renderStars = (): React.ReactNode[] => {
        const stars = [];
        for (let i = 0; i < props.total; i++) {
            if (i < props.filled!) {
                stars.push(<FontAwesomeIcon key={i} icon={faStar} className={styles.yellow} />);
            } else {
                stars.push(<FontAwesomeIcon key={i} icon={faStarOutline} />);
            }
        }

        return stars;
    };

    return (
        <span
            onClick={props.onClick}
            className={classNames([!!props.onClick && styles.clickable])}
            title={props.title}
        >
            {renderStars()}
        </span>
    )
};