import * as React from "react";
import { useApp } from "Context/Resources";
import { useUser } from "UserContext";
import { classNames } from "@kaladel/react/Utilities";

interface CurrencyDisplayProps {
    value: number;
    currencyId: number;
    classNameBelowZero?: string;
    classNameAboveZero?: string;
    asAbsolute?: boolean;
}

export const CurrencyDisplay: React.FC<CurrencyDisplayProps> = (props) => {
    const { resource } = useApp();
    const { currencies } = useUser();
    const currency = currencies.find((c) => c.id === props.currencyId);

    if (!currency) return null;

    return (
        <span
            title={resource(`Currency-${currency.isoCode}`)}
            className={classNames([
                props.value < 0 && !!props.classNameBelowZero && props.classNameBelowZero,
                props.value > 0 && !!props.classNameAboveZero && props.classNameAboveZero
            ])}
        >
            {!!currency.symbol && !currency.symbolPostValue && currency.symbol}
            {(!props.asAbsolute ? props.value : Math.abs(props.value)).toLocaleString(navigator.language, { minimumFractionDigits: currency.decimalPlaces, maximumFractionDigits: currency.decimalPlaces })}
            {!!currency.symbol && currency.symbolPostValue && currency.symbol}
        </span>
    );
};