import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SitesArea } from "Pages/Sites/SitesArea";
import { AuthenticationArea } from "Pages/Authentication/AuthenticationArea";
import { ConfigurationClient } from "ApiFrontEndClient";
import { AppContext } from "./Context/Resources";
import { Dictionary } from "./Helpers/Dictionary";
import { HttpStatusCode } from "@kaladel/react/Utilities";
import { AlertGet, AlertsClient, CurrenciesClient, CurrencyGet, SessionClient, SessionGet, StaticClient, StaticCountry } from "ApiClient/ApiClient";
import { UserContext } from "UserContext";
import { ProfileArea } from "Pages/Profile/ProfileArea";
import { Layout } from "Layout";
import { StaticDataContext } from "Pages/Sites/StaticDataContext";
import { TestArea } from "Pages/Test/TestArea";

export const App: React.FC = () => {
    const [resources, setResources] = React.useState<{ [key: string]: string; } | null>(null);
    const [session, setSession] = React.useState<SessionGet | null>(null);
    const [currencies, setCurrencies] = React.useState<CurrencyGet[]>([]);
    const [countries, setCountries] = React.useState<StaticCountry[] | null>(null);
    const [alerts, setAlerts] = React.useState<AlertGet[]>([]);

    const getResources = async () => {
        const client = new ConfigurationClient();
        var response = await client.resources();
        if (response.status === HttpStatusCode.Http200Ok) {
            setResources(response.result);
        }
    };

    React.useEffect(() => {
        getResources();
        getSession();
        getCurrencies();
        getStaticData();
    }, []);

    const resource = (key: string, variables?: Dictionary<string | number>) => {
        if (!!resources) {
            const result = resources[key];

            if (!!result) {
                let value = result;
                if (!!variables) {
                    Object.keys(variables).forEach(k => {
                        value = value.split(`{${k}}`).join(variables[k].toString());
                    });
                }

                return value;
            }
        }

        return `{{${key}}}`;
    };

    const getSession = async () => {
        const client = new SessionClient();
        var response = await client.get();
        if (response.status === HttpStatusCode.Http200Ok) {
            setSession(response.result);
        }
    };

    const getCurrencies = async () => {
        const client = new CurrenciesClient();
        var response = await client.getAll();
        if (response.status === HttpStatusCode.Http200Ok) {
            setCurrencies(response.result);
        }
    };

    const getStaticData = async () => {
        const client = new StaticClient();
        var response = await client.getCountries();
        if (response.status = HttpStatusCode.Http200Ok) {
            setCountries(response.result);
        }
    };

    const getAlerts = async () => {
        if (!session) return;
        const client = new AlertsClient();
        const response = await client.getAll();
        if (response.status = HttpStatusCode.Http200Ok) {
            setAlerts(response.result);
        } else {
            setAlerts([]);
        }
    };

    React.useEffect(() => {
        getAlerts();
    }, [session]);

    if (!resources || !currencies || !countries) return null;

    return (

        <AppContext.Provider value={{ resource }}>
            <UserContext.Provider value={{ currencies, session, refreshSession: () => getSession(), alerts, refreshAlerts: () => getAlerts() }}>
                <StaticDataContext.Provider value={{ countries }}>
                    <Layout>
                        <Routes>
                            <Route index element={<Navigate to="sites" />} />
                            <Route path="auth/*" element={<AuthenticationArea />} />
                            <Route path="profile/*" element={<ProfileArea />} />
                            <Route path="sites/*" element={<SitesArea />} />
                            <Route path="test/*" element={<TestArea />} />
                        </Routes>
                    </Layout>
                </StaticDataContext.Provider>
            </UserContext.Provider>
        </AppContext.Provider>
    );
};