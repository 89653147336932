import { Alert, Button, Card, FlexTable, LoadingError, WithLineBreaks } from "@kaladel/react/Components";
import { HttpStatusCode, Severity } from "@kaladel/react/Utilities";
import { AddressGet, AddressPost, AddressPut, PhoneNumberGet, PhoneNumberPost, PhoneNumberPut, ProfileAddressesClient, ProfileClient, ProfileGet, ProfilePhoneNumbersClient } from "ApiClient/ApiClient";
import { SectionHeader } from "Components";
import { useApp } from "Context/Resources";
import * as React from "react";
import styles from "./Profile.module.scss";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PhoneNumberNewModal } from "Modals/PhoneNumberNewModal";
import { PhoneNumberEditModal } from "Modals/PhoneNumberEditModal";
import { AddressNewModal } from "Modals/AddressNewModal";
import { AddressEditModal } from "Modals/AddressEditModal";
import { Star } from "Components/Star/Star";
import { useUser } from "UserContext";

export const Profile: React.FC = () => {
    const { resource } = useApp();
    const { refreshAlerts } = useUser();

    const [profile, setProfile] = React.useState<ProfileGet | null | undefined>(null);
    const [phoneNumbers, setPhoneNumbers] = React.useState<PhoneNumberGet[] | null | undefined>(null);
    const [editPhoneNumber, setEditPhoneNumber] = React.useState<PhoneNumberGet | null>(null);
    const [addresses, setAddresses] = React.useState<AddressGet[] | null | undefined>(null);
    const [editAddress, setEditAddress] = React.useState<AddressGet | null>(null);

    const [newPhoneNumberModalOpen, setNewPhoneNumberModalOpen] = React.useState<boolean>(false);
    const [newAddressModalOpen, setNewAddressModalOpen] = React.useState<boolean>(false);

    const getProfile = async () => {
        const client = new ProfileClient();
        const response = await client.get();

        switch (response.status) {
            case HttpStatusCode.Http200Ok:
                setProfile(response.result);
                return;
        }

        setProfile(undefined);
    };

    const getPhoneNumbers = async () => {
        const client = new ProfilePhoneNumbersClient();
        const response = await client.getAll();

        switch (response.status) {
            case HttpStatusCode.Http200Ok:
                setPhoneNumbers(response.result);
                return;
        }

        setPhoneNumbers(undefined);
    };

    const getAddresses = async () => {
        const client = new ProfileAddressesClient();
        const response = await client.getAll();

        switch (response.status) {
            case HttpStatusCode.Http200Ok:
                setAddresses(response.result);
                return;
        }

        setAddresses(undefined);
    };

    const handlePhoneNumberModalClosed = (dirty: boolean) => {
        setNewPhoneNumberModalOpen(false);
        setEditPhoneNumber(null);
        if (dirty) {
            getPhoneNumbers();
            refreshAlerts();
        }
    };

    const handleAddressModalClosed = (dirty: boolean) => {
        setNewAddressModalOpen(false);
        setEditAddress(null);
        if (dirty) {
            getAddresses();
            refreshAlerts();
        }
    };

    const onPostPhoneNumber = async (phoneNumber: PhoneNumberPost) => {
        const client = new ProfilePhoneNumbersClient();
        return await client.post(phoneNumber);
    };

    const onPostAddress = async (address: AddressPost) => {
        const client = new ProfileAddressesClient();
        return await client.post(address);
    };

    const onPutPhoneNumber = async (phoneNumber: PhoneNumberPut) => {
        if (!editPhoneNumber) throw "No phone number currently being edited";
        const client = new ProfilePhoneNumbersClient();
        return await client.put(editPhoneNumber.id, phoneNumber);
    };

    const onPutAddress = async (address: AddressPut) => {
        if (!editAddress) throw "No address currently being edited";
        const client = new ProfileAddressesClient();
        return await client.put(editAddress.id, address);
    };

    const onDeletePhoneNumber = async () => {
        if (!editPhoneNumber) throw "No phone number currently being edited";
        const client = new ProfilePhoneNumbersClient();
        return await client.delete(editPhoneNumber.id);
    };

    const onDeleteAddress = async () => {
        if (!editAddress) throw "No address currently being edited";
        const client = new ProfileAddressesClient();
        return await client.delete(editAddress.id);
    };

    const handleMakeAddressPrimaryClicked = async (address: AddressGet) => {
        if (!profile) throw "No profile loaded";
        const client = new ProfileClient();
        const response = await client.put({ primaryAddressId: address.id });
        if (response.status === HttpStatusCode.Http204NoContent) {
            setProfile({
                ...profile,
                primaryAddressId: address.id
            });
        }
    };

    React.useEffect(() => {
        getProfile();
        getPhoneNumbers();
        getAddresses();
    }, []);

    return (
        <>
            {newPhoneNumberModalOpen && (
                <PhoneNumberNewModal
                    onClose={handlePhoneNumberModalClosed}
                    onPost={onPostPhoneNumber}
                />
            )}

            {!!editPhoneNumber && (
                <PhoneNumberEditModal
                    onClose={handlePhoneNumberModalClosed}
                    onPut={onPutPhoneNumber}
                    onDelete={onDeletePhoneNumber}
                    phoneNumber={editPhoneNumber}
                />
            )}

            {newAddressModalOpen && (
                <AddressNewModal
                    onClose={handleAddressModalClosed}
                    onPost={onPostAddress}
                />
            )}

            {!!editAddress && (
                <AddressEditModal
                    onClose={handleAddressModalClosed}
                    onPut={onPutAddress}
                    onDelete={onDeleteAddress}
                    address={editAddress}
                />
            )}

            <div className={styles.profile}>
                <Card>
                    <SectionHeader
                        title={resource("Profile-Essentials")}
                    />

                    <LoadingError
                        value={profile}
                    >
                        {profile && (
                            <div>
                                <div>
                                    {profile.givenName} {profile.familyName}
                                </div>
                                <div>
                                    {profile.email}
                                </div>
                            </div>
                        )}
                    </LoadingError>
                </Card>

                <Card>
                    <SectionHeader
                        title={resource("Profile-Addresses")}
                        right={
                            <>
                                <Button
                                    onClick={() => setNewAddressModalOpen(true)}
                                    severity={Severity.Success}
                                    icon={faPlus}
                                >
                                    {resource("Button-New")}
                                </Button>
                            </>
                        }
                    />

                    <LoadingError
                        value={addresses}
                    >
                        {!addresses?.length && (
                            <Alert
                                severity={Severity.Warning}
                            >
                                {resource("Profile-NoAddresses")}
                            </Alert>
                        )}

                        {!!addresses?.length && (
                            <div className={styles.addresses}>
                                {addresses.map(address => (
                                    <Card
                                        key={address.id}
                                        bodyClassName={styles.addressCard}
                                    >
                                        <div>
                                            {!!profile && (
                                                <Star
                                                    total={1}
                                                    filled={address.id === profile.primaryAddressId ? 1 : 0}
                                                    title={resource("Button-MakePrimary")}
                                                    onClick={() => handleMakeAddressPrimaryClicked(address)}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            {!!address.addressFreeText && (
                                                <div><WithLineBreaks input={address.addressFreeText} /></div>
                                            )}
                                            {!!address.city && <div>{address.city}</div>}
                                            {!!address.state && <div>{address.state}</div>}
                                            {!!address.postCode && <div>{address.postCode}</div>}
                                            {!!address.countryId && <div>{resource(`Country-${address.countryId}`)}</div>}
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        )}
                    </LoadingError>
                </Card>

                <Card>
                    <SectionHeader
                        title={resource("Profile-PhoneNumbers")}
                        right={
                            <>
                                <Button
                                    onClick={() => setNewPhoneNumberModalOpen(true)}
                                    severity={Severity.Success}
                                    icon={faPlus}
                                >
                                    {resource("Button-New")}
                                </Button>
                            </>
                        }
                    />

                    <LoadingError
                        value={phoneNumbers}
                    >
                        {!phoneNumbers?.length && (
                            <Alert
                                severity={Severity.Warning}
                            >
                                {resource("Profile-NoPhoneNumbers")}
                            </Alert>
                        )}

                        {!!phoneNumbers?.length && (
                            <FlexTable
                                items={phoneNumbers}
                                rowKey={pn => pn.id}
                                onRowClick={pn => setEditPhoneNumber(pn)}
                                showHeader={true}
                                groups={[
                                    {
                                        shrink: true,
                                        basis: "100px",
                                        columns: [
                                            {
                                                id: "type",
                                                basis: "100px",
                                                shrink: true,
                                                title: resource("PhoneNumbers-Type"),
                                                display: phoneNumber => resource(`PhoneNumberType-${phoneNumber.type}`)
                                            },
                                        ]
                                    },
                                    {
                                        shrink: true,
                                        basis: "200px",
                                        columns: [
                                            {
                                                id: "number",
                                                basis: "200px",
                                                shrink: true,
                                                title: resource("PhoneNumbers-Number"),
                                                titleClassName: styles.inUse,
                                                display: tc => tc.number
                                            }
                                        ]
                                    },
                                    {
                                        grow: true,
                                        basis: "0px",
                                        columns: []
                                    }
                                ]}
                            />
                        )}
                    </LoadingError>
                </Card>
            </div>
        </>
    );
};