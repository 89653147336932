import { Configuration } from "Context/Configuration";

export class ApiBase {
    protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
        //let token = this.getToken(); // your custom logic to get the token
        //if (!!token) {
        //    options.headers = {
        //        ...options.headers,
        //        Authorization: 'Bearer ' + token,
        //    };
        //}
        options.credentials = "include";
        return Promise.resolve(options);
    };

    //private getToken(): string {
    //    return localStorage.getItem("token");
    //}

    protected getBaseUrl = (_: string, _baseUrl?: string): string => {
      return Configuration.get().appUrl!;
    }

    protected transformResult(_url: string, response: Response, processor: (response: Response) => any) {
        if (response.status === 401) {
            localStorage.removeItem("token");
            location.href = "/auth/start";
        }

        return processor(response);
    }
}