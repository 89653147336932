// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hw7GFwjBp5N0oG5bogBP{color:gold}.WyGm3hel5_cQtW3Q2c3y{cursor:pointer}", "",{"version":3,"sources":["webpack://./Client/Components/Star/Star.module.scss"],"names":[],"mappings":"AACA,sBACI,UAAA,CAGJ,sBACI,cAAA","sourcesContent":["@import \"~/Client/Styles/theme.scss\";\n.yellow {\r\n    color: gold;\r\n}\r\n\r\n.clickable {\r\n    cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"yellow": "Hw7GFwjBp5N0oG5bogBP",
	"clickable": "WyGm3hel5_cQtW3Q2c3y"
};
export default ___CSS_LOADER_EXPORT___;
