import * as React from "react";
import { Profile } from "./Profile";
import { Route, Routes } from "react-router-dom";
import { useApp } from "Context/Resources";
import { Button, Content, TopBar } from "@kaladel/react/Components";
import { Severity } from "@kaladel/react/Utilities";
import styles from "./ProfileArea.module.scss";

export const ProfileArea: React.FC = () => {
    const { resource } = useApp();

    return (
        <>
            <TopBar
                barIndex={1}
                appName={resource("Link-Profile")}
                homeLink=""
                rightContent={<Button severity={Severity.Danger}>{resource("Button-Logout")}</Button>}
                className={styles.areaBar}
            />
            <Content>
                <Routes>
                    <Route index element={<Profile />} />
                </Routes>
            </Content>
        </>
    );
};