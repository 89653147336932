import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Sites } from "./Sites";
import { SiteGet, SitesClient } from "ApiClient/ApiClient";
import { SitesContext } from "./SitesContext";
import { Loading } from "@kaladel/react/Components";
import { HttpStatusCode } from "@kaladel/react/Utilities";

const SiteAreaLazy = React.lazy(() => import("./Site/SiteArea"));

export const SitesArea: React.FC = () => {
    const sitesClient = new SitesClient();

    const [sites, setSites] = React.useState<SiteGet[] | null>(null);

    React.useEffect(() => {
        getSites();
    }, []);

    const getSites = async () => {
        var response = await sitesClient.getAll();
        if (response.status === HttpStatusCode.Http200Ok) {
            setSites(response.result);
        }
    };


    if (!sites) return null;

    return (
        <SitesContext.Provider value={sites}>
            <React.Suspense fallback={<Loading />}>
                <Routes>
                    <Route index element={<Sites />} />
                    <Route path=":siteId/*" element={<SiteAreaLazy />} />
                </Routes>
            </React.Suspense>
        </SitesContext.Provider>
    );
};