// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mCdZlkSJHyLh8hlPyQFJ{margin-top:30px;text-align:center}", "",{"version":3,"sources":["webpack://./Client/Pages/Authentication/Return.module.scss"],"names":[],"mappings":"AACA,sBACI,eAAA,CACA,iBAAA","sourcesContent":["@import \"~/Client/Styles/theme.scss\";\n.page {\r\n    margin-top: 30px;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "mCdZlkSJHyLh8hlPyQFJ"
};
export default ___CSS_LOADER_EXPORT___;
