import { Button, Form, Input, Modal, ModalSize, Select } from "@kaladel/react/Components";
import { useValidator } from "@kaladel/react/Hooks";
import { HttpStatusCode, Severity } from "@kaladel/react/Utilities";
import { PhoneNumberGet, PhoneNumberPut, PhoneNumberType, SwaggerResponse } from "ApiClient/ApiClient";
import { useApp } from "Context/Resources";
import { enumToArray } from "Helpers/Enum";
import * as React from "react";
import { ConfirmModal } from "./ConfirmModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface PhoneNumberEditModalProps {
    phoneNumber: PhoneNumberGet;
    onPut: (model: PhoneNumberPut) => Promise<SwaggerResponse<void>>;
    onDelete: () => Promise<SwaggerResponse<void>>;
    onClose: (dirty: boolean) => void;
}

export const PhoneNumberEditModal: React.FC<PhoneNumberEditModalProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();

    const [type, setType] = React.useState<string>(props.phoneNumber.type.toString());
    const [number, setNumber] = React.useState<string>(props.phoneNumber.number);
    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);

    const handleSaveClicked = async () => {
        if (!validator.canSubmit()) return;

        const model: PhoneNumberPut = {
            type: parseInt(type!),
            number
        };
        const response = await props.onPut(model);

        if (response.status === HttpStatusCode.Http204NoContent) {
            props.onClose(true);
        }
    };

    const handleDeleteConfirmed = async () => {
        const response = await props.onDelete();

        if (response.status === HttpStatusCode.Http204NoContent) {
            props.onClose(true);
        }
    };

    return (
        <Modal
            onClose={props.onClose}
            title={resource("PhoneNumbers-EditPhoneNumber")}
            size={ModalSize.Medium}
        >
            {confirmDelete && (
                <ConfirmModal
                    severity={Severity.Danger}
                    message={resource("PhoneNumbers-ConfirmDeletePhoneNumber")}
                    positiveButton={resource("Button-Delete")}
                    negativeButton={resource("Button-Cancel")}
                    onPositiveClicked={() => handleDeleteConfirmed()}
                    onNegativeClicked={() => setConfirmDelete(false)}
                />
            )}

            <Form>

                <Select
                    id="type"
                    label={resource("PhoneNumbers-Type")}
                    items={enumToArray(PhoneNumberType)}
                    values={(item) => item.value.toString()}
                    descriptions={(item) => resource(`PhoneNumberType-${item.value}`)}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                    autoFocus={true}
                />

                <Input
                    id="number"
                    label={`${resource("PhoneNumbers-Number")}`}
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    validator={validator}
                    validations={{ required: true, maxLength: 30, minLength: 8 }}
                    autoFocus={true}
                />

                <div className="buttons right">
                    <div>
                        <Button
                            severity={Severity.Success}
                            onClick={() => handleSaveClicked()}
                            type="submit"
                        >
                            {resource("Button-Save")}
                        </Button>
                        <Button
                            severity={Severity.Primary}
                            onClick={() => props.onClose(false)}
                            type="button"
                        >
                            {resource("Button-Cancel")}
                        </Button>
                    </div>
                    <div>
                        <Button
                            severity={Severity.Danger}
                            onClick={() => setConfirmDelete(true)}
                            icon={faTrash}
                        >
                            {resource("Button-Delete")}
                        </Button>
                    </div>
                </div>

            </Form>
        </Modal>
    )
};