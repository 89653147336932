// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hwZVWcgYITT6YhNiVnbW{display:flex;justify-content:space-between;margin-bottom:6px;align-items:start}.hwZVWcgYITT6YhNiVnbW .NX_Devy_8p92TPp9lRTQ{flex-grow:1;font-size:22px;font-weight:500}.hwZVWcgYITT6YhNiVnbW .OkuMpiWtarTXTZ4Qy7QE{text-align:right}", "",{"version":3,"sources":["webpack://./Client/Components/SectionHeader/SectionHeader.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,6BAAA,CACA,iBAAA,CACA,iBAAA,CAEA,4CACI,WAAA,CACA,cAAA,CACA,eAAA,CAGJ,4CACI,gBAAA","sourcesContent":["@import \"~/Client/Styles/theme.scss\";\n.container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-bottom: 6px;\r\n    align-items: start;\r\n\r\n    .left {\r\n        flex-grow: 1;\r\n        font-size: 22px;\r\n        font-weight: 500;\r\n    }\r\n\r\n    .right {\r\n        text-align: right;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "hwZVWcgYITT6YhNiVnbW",
	"left": "NX_Devy_8p92TPp9lRTQ",
	"right": "OkuMpiWtarTXTZ4Qy7QE"
};
export default ___CSS_LOADER_EXPORT___;
