interface Array<T> {
    /**
     * Checks if any item in the array matches the predicate
     * @param predicate A boolean function to match to each item
     */
    any(predicate: (item: T) => boolean): boolean;

    /**
     * Checks if the specified item is included in the array
     * @param item The item to check for
     */
    contains(item: T): boolean;

    distinct(): Array<T>;

    /**
     * Returns an array which excludes the specified item
     * @param item The item to exclude
     */
    except(item: T): Array<T>;

    /**
     * Returns an array which excludes the items which match the predicate
     * @param predicate A boolean function to match to each item to exclude
     */
    exceptAny(predicate: (item: T) => boolean): Array<T>;

    /**
     * Returns the maximum value in the array
     * @param selector A function to select the value to compare
     */
    max(selector: (item: T) => number): number;

    /**
    * Returns the minimum value in the array
    * @param selector A function to select the value to compare
    */
    min(selector: (item: T) => number): number;

    orderBy(selector: (item: T) => string | number): Array<T>;

    orderByDescending(selector: (item: T) => string | number): Array<T>;

    /**
     * Returns a page of an array
     * @param pageNumber The page number to return
     * @param pageSize The size of each page
     */
    page(pageNumber: number, pageSize: number): Array<T>;

    /**
    * Returns a new array with the selected values
    * @param selector A function to select the value to return
    */
    select<U>(selector: (item: T) => U): Array<U>;

    /**
     * Returns an array excluding the specified number of items from the begining
     * @param total The number of items to exclude
     */
    skip(total: number): Array<T>;

    /**
     * Returns the specified number of items from the top of the array
     * @param total The number of items to return
     */
    take(total: number): Array<T>;
}

Array.prototype.any = function <T>(predicate: (item: T) => boolean): boolean {
    return (this.findIndex(predicate) > -1);
};

Array.prototype.contains = function <T>(item: T) {
    return this.indexOf(item) > -1;
};

Array.prototype.distinct = function <T>(): Array<T> {
    return this.filter((value, index, self) => self.indexOf(value) === index);
};

Array.prototype.except = function <T>(item: T) {
    if (!this.contains(item)) return this;

    const copy = [...this];

    copy.splice(this.indexOf(item), 1);

    return copy;
};

Array.prototype.exceptAny = function <T>(predicate: (item: T) => boolean): Array<T> {
    const copy: Array<T> = [];

    for (let currentItem of this) {
        if (!predicate(currentItem)) {
            copy.push(currentItem);
        }
    }

    return copy;
};

Array.prototype.max = function <T>(selector: (item: T) => number): number {
    return Math.max(...this.map(selector));
};

Array.prototype.min = function <T>(selector: (item: T) => number): number {
    return Math.min(...this.map(selector));
};

Array.prototype.orderBy = function <T>(selector: (item: T) => string | number): Array<T> {
    return this.sort((a, b) => {
        const valueA = selector(a);
        const valueB = selector(b);

        if (valueA > valueB) return 1;
        if (valueA < valueB) return -1;
        return 0;
    });
}

Array.prototype.orderByDescending = function <T>(selector: (item: T) => string | number): Array<T> {
    return this.sort((a, b) => {
        const valueA = selector(a);
        const valueB = selector(b);

        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
        return 0;
    });
}

Array.prototype.page = function <T>(pageNumber: number, pageSize: number): Array<T> {
    if (pageSize === 0) return this;
    return this.skip((pageNumber - 1) * pageSize).take(pageSize);
};

Array.prototype.select = function <T, U>(selector: (item: T) => U): Array<U> {
    return this.map(selector);
};

Array.prototype.skip = function <T>(total: number): Array<T> {
    if (total > this.length) return [];
    return this.slice(total, this.length);
};

Array.prototype.take = function <T>(total: number): Array<T> {
    if (this.length <= total) return [...this];
    return this.slice(0, total);
};
