import { Severity } from "@kaladel/react/Utilities";
import * as React from "react";
import { Alert, Button, Modal, ModalSize } from "@kaladel/react/Components";

interface ConfirmModalProps {
    message: string;
    severity: Severity;
    positiveButton: string;
    negativeButton: string;
    onPositiveClicked: () => void;
    onNegativeClicked: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
    return (
        <Modal
            onClose={() => props.onNegativeClicked()}
            size={ModalSize.Small}
        >
            <Alert
                severity={props.severity}
            >
                {props.message}
            </Alert>

            <div className="buttons right">
                <div>
                    <Button
                        severity={props.severity}
                        onClick={() => props.onPositiveClicked()}
                    >
                        {props.positiveButton}
                    </Button>

                    <Button
                        severity={Severity.Primary}
                        onClick={() => props.onNegativeClicked()}
                    >
                        {props.negativeButton}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};