import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Start } from "./Start";
import { Return } from "./Return";

export const AuthenticationArea: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/" />} />
      <Route path="start" element={<Start />} />
      <Route path="return" element={<Return />} />
    </Routes>
  );
}