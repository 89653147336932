import * as React from "react";
import { useApp } from "./Context/Resources";
import styles from "./Layout.module.scss";
import { useUser } from "UserContext";
import { Alert, Content, Gravatar, TopBar } from "@kaladel/react/Components";
import { useNavigate } from "react-router-dom";
import { AlertArea } from "ApiClient/ApiClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

interface ILayoutProps {
    children?: React.ReactNode;
}

export const Layout: React.FunctionComponent<ILayoutProps> = (props): React.ReactNode => {
    const { resource } = useApp();
    const { session, alerts } = useUser();
    const navigate = useNavigate();

    const getUserNotifications = () => {
        const userAlerts = alerts?.filter(a => a.alertArea === AlertArea.Account);
        if (!userAlerts?.length) return null;

        const maxSeverity = userAlerts.max(a => a.severity);

        return (
            <div
                className={styles.alertWrapper}
                title={resource("Profile-HasNotifications")}
                onClick={() => navigate(`/profile`)}
            >
                <Alert
                    severity={maxSeverity}
                    className={styles.alert}
                >
                    {userAlerts.length}
                </Alert>
            </div>
        );
    };

    return (
        <>
            <TopBar
                className={styles.topBar}
                appName="Our Hub"
                homeLink="/sites"
                rightContent={
                    <>
                        {!!session && (
                            <>
                                {!!session.globalPermissions?.length && (
                                    <div className={styles.user} onClick={() => navigate("/admin")}>
                                        <span><FontAwesomeIcon icon={faCog} /></span>
                                    </div>
                                )}
                                <div className={styles.user} onClick={() => navigate("/profile")}>
                                    {getUserNotifications()}
                                    <Gravatar hash={session.emailHash} size={32} />
                                    <span>{session.givenName} {session.familyName}</span>
                                </div>
                            </>
                        )}
                    </>
                }
            />
            <Content>
                {props.children}
            </Content>
        </>
    );
};