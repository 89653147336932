import { HttpStatusCode } from "@kaladel/react/Utilities";
import { Configuration } from "../Context/Configuration";
import { SwaggerResponse } from "./ApiClient";

export class ApiBase {
    protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
        options.credentials = "include";
        return Promise.resolve(options);
    };

    protected getBaseUrl = (_: string, _baseUrl?: string): string => {
        return Configuration.get().apiUrl!;
    }

    protected transformResult(_url: string, response: Response, processor: (response: Response) => any) {
        if (response.status === HttpStatusCode.Http401Unauthorized) {
            const config = Configuration.get();
            localStorage.removeItem("token");
            location.href = `${config.authUrl}/?appId=${config.authAppId}&returnPath=${encodeURIComponent(location.pathname)}`;
            return;
        }

        if (response.status >= 400 && response.status < 600) {
            return new SwaggerResponse<null>(response.status, response.headers, null);
        }

        return processor(response);
    }
}