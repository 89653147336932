import { Button, Form, Input, Modal, ModalSize, Select } from "@kaladel/react/Components";
import { useValidator } from "@kaladel/react/Hooks";
import { HttpStatusCode, Severity } from "@kaladel/react/Utilities";
import { PhoneNumberPost, PhoneNumberType, SwaggerResponse } from "ApiClient/ApiClient";
import { useApp } from "Context/Resources";
import { enumToArray } from "Helpers/Enum";
import * as React from "react";

interface PhoneNumberNewModalProps {
    onPost?: (model: PhoneNumberPost) => Promise<SwaggerResponse<string>>;
    onAdd?: (model: PhoneNumberPost) => void;
    onClose: (dirty: boolean) => void;
}

export const PhoneNumberNewModal: React.FC<PhoneNumberNewModalProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();

    const [type, setType] = React.useState<string>("");
    const [number, setNumber] = React.useState<string>("");

    const handleSaveClicked = async () => {
        if (!validator.canSubmit()) return;

        const model: PhoneNumberPost = {
            type: parseInt(type!),
            number
        };

        if (props.onAdd) {
            props.onAdd(model);
            props.onClose(true);
            return;
        }

        if (!!props.onPost) {
            const response = await props.onPost(model);

            if (response.status === HttpStatusCode.Http201Created) {
                props.onClose(true);
            }
        }
    };

    return (
        <Modal
            onClose={props.onClose}
            title={resource("PhoneNumbers-NewPhoneNumber")}
            size={ModalSize.Medium}
        >
            <Form>

                <Select
                    id="type"
                    label={resource("PhoneNumbers-Type")}
                    items={enumToArray(PhoneNumberType)}
                    values={(item) => item.value.toString()}
                    descriptions={(item) => resource(`PhoneNumberType-${item.value}`)}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                    autoFocus={true}
                />

                <Input
                    id="number"
                    label={`${resource("PhoneNumbers-Number")}`}
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    validator={validator}
                    validations={{ required: true, maxLength: 30, minLength: 8 }}
                />

                <div className="buttons right">
                    <div>
                        <Button
                            severity={Severity.Success}
                            onClick={() => handleSaveClicked()}
                            type="submit"
                        >
                            {resource("Button-Save")}
                        </Button>
                        <Button
                            severity={Severity.Primary}
                            onClick={() => props.onClose(false)}
                            type="button"
                        >
                            {resource("Button-Cancel")}
                        </Button>
                    </div>
                </div>

            </Form>
        </Modal>
    );
};