import * as React from "react";
import styles from "./SectionHeader.module.scss";

interface SectionHeaderProps {
    title: React.ReactNode;
    right?: React.ReactNode;
}

export const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.left}>{props.title}</div>
            {props.right && <div className={styles.right}>{props.right}</div>}
        </div>
    );
};