import * as React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import styles from "./Sites.module.scss";
import { useSites } from "./SitesContext";
import { Card } from "@kaladel/react/Components";
import { SectionHeader } from "Components/SectionHeader/SectionHeader";
import { useApp } from "Context/Resources";


export const Sites: React.FC = () => {
    const navigate = useNavigate();
    const { resource } = useApp();
    const sites = useSites()!;

    if (sites.length === 1) {
        navigate(`${sites[0].id}`);
        return null;
    }

    return (
        <div>
            <SectionHeader
                title={resource("YourSites")}
            />
            <div className="flex wrap">
                {sites.map((site) =>
                    <Card
                        onClick={() => navigate(`${site.id}`)}
                        key={site.id}
                        className={styles.card}
                        cover={
                            <div className={styles.image}>
                                <FontAwesomeIcon icon={faHouse} size="5x" />
                            </div>
                        }
                        title={site.name}
                    />
                )}
            </div>
        </div>
    );
};