// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KAdZMERnYrvwMDdjvNCA{background-color:#1755bf;color:#fff}.KAdZMERnYrvwMDdjvNCA .FaV2Da8asQd00k1g3wlq{display:flex;flex-direction:row;align-items:center;gap:8px;justify-content:flex-end;font-weight:500;cursor:pointer}.KAdZMERnYrvwMDdjvNCA .FaV2Da8asQd00k1g3wlq .Sge0nnftbHqdkZim8Ivy{padding:0 6px}", "",{"version":3,"sources":["webpack://./Client/Layout.module.scss","webpack://./Client/Styles/theme.scss"],"names":[],"mappings":"AAGA,sBACI,wBCIY,CDHZ,UAAA,CAEA,4CACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CAEA,kEACI,aAAA","sourcesContent":["@import \"~/Client/Styles/theme.scss\";\n@import \"Styles/theme.scss\";\r\n\r\n.topBar {\r\n    background-color: $theme-primary;\r\n    color: text-on($theme-primary);\r\n\r\n    .user {\r\n        display: flex;\r\n        flex-direction: row;\r\n        align-items: center;\r\n        gap: 8px;\r\n        justify-content: flex-end;\r\n        font-weight: 500;\r\n        cursor: pointer;\r\n\r\n        .alert {\r\n            padding: 0 6px;\r\n        }\r\n    }\r\n}","@import \"../../node_modules/@kaladel/react/Styles/theme.scss\";\r\n\r\n$smallLimit: 750px;\r\n$mediumLimit: 900px;\r\n$largeLimit: 1280px;\r\n\r\n$theme-placeholder-background: #d2e3ff;\r\n$theme-placeholder-color: #1755bf;\r\n$theme-primary: #1755bf;\r\n$theme-primary-light: #1f71ff;\r\n$theme-primary-feint: #d2e3ff;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBar": "KAdZMERnYrvwMDdjvNCA",
	"user": "FaV2Da8asQd00k1g3wlq",
	"alert": "Sge0nnftbHqdkZim8Ivy"
};
export default ___CSS_LOADER_EXPORT___;
