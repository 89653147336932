import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Inputs } from "./Inputs";
import { TestFlexGrid } from "./TestFlexGrid";

export const TestArea: React.FC = () => {
    return (
        <Routes>
            <Route path="flexgrid/*" element={<TestFlexGrid /> } />
            <Route path="inputs/*" element={<Inputs />} />
        </Routes>
    );
}