import { classNames } from "@kaladel/react/Utilities";
import * as React from "react";
import { NavigateFunction, Location } from "react-router-dom";
import styles from "./CardMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

interface CardMenuProps {
    items: CardMenuItem[];
    navigate: NavigateFunction;
    location: Location;
}

interface CardMenuItem {
    name: string;
    path: string;
    hidden?: boolean;
}

export const CardMenu: React.FC<CardMenuProps> = (props) => {
    return (
        <div className={styles.cardMenu}>
            {props.items.filter(i => !i.hidden).map(item =>
                <div
                    key={item.path}
                    onClick={() => props.navigate(item.path)}
                    className={classNames([
                        styles.item,
                        location.pathname.split("/").reverse()[0] === item.path && styles.active
                    ])}
                >
                <div>
                        {item.name}
                    </div>
                    {location.pathname.split("/").reverse()[0] === item.path && (
                        <div>
                            <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};