import { Card, FlexGrid } from "@kaladel/react/Components";
import * as React from "react";

interface Item {
    a: string;
    b: string;
    c: string;
}

export const TestFlexGrid: React.FC = () => {
    const items: Item[] = [
        { a: "1", b: "2", c: "3" },
        { a: "4", b: "5", c: "6" },
        { a: "7", b: "8", c: "9" }
    ];

    return (
        <FlexGrid
            items={items}
            itemKey={item => item.a}
            minTileWidth="400px"
            gap="4px"
            template={(item, key) => (
                <Card key={key}>
                    <div>{item.a}</div>
                    <div>{item.b}</div>
                    <div>{item.c}</div>
                </Card>
            )}
        />
    )
};