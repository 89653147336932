import { createContext, useContext } from "react";
import { StaticCountry } from "ApiClient/ApiClient";

export const StaticDataContext = createContext<StaticData | null > (null);

export function useStaticData() {
    return useContext(StaticDataContext);
}

export interface StaticData {
    countries: StaticCountry[];
}