import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SessionClient } from "ApiClient/ApiClient";
import { useApp } from "Context/Resources";
import styles from "./Return.module.scss";
import { LoadingError } from "@kaladel/react/Components";
import { HttpStatusCode } from "@kaladel/react/Utilities";
import { useUser } from "UserContext";

export const Return: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sessionClient = new SessionClient();
    const [loginState, setLoginState] = React.useState<null | undefined>(null);
    const { resource } = useApp();
    const { refreshSession } = useUser();

    if (!searchParams || !searchParams.has("tkn")) navigate("/");

    const attemptLogin = async () => {
        setLoginState(null);

        const response = await sessionClient.post(searchParams.get("tkn")!);

        switch (response.status) {
            case HttpStatusCode.Http204NoContent: {
                refreshSession();
                if (searchParams.has("returnPath")) {
                    navigate(searchParams.get("returnPath")!);
                } else {
                    navigate("/");
                }
                break;
            }
            default: {
                setLoginState(undefined);
            }
        }
    };

    React.useEffect(() => {
        attemptLogin();
    }, []);

    return (
        <div className={styles.page}>
            <LoadingError
                value={loginState}
                retry={() => attemptLogin()}
                large={true}
                errorText={resource("General-LoginError")}
                retryText={resource("General-Retry")}
            />
        </div>
    );
};