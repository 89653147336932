// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XC1XQPSzu2WdWeUF43u8 .UVRreAw6epUGPHNcqeLB{display:flex;flex-flow:row;justify-content:space-between;cursor:pointer;padding:4px 0;border-bottom:1px solid #ccc}.XC1XQPSzu2WdWeUF43u8 .UVRreAw6epUGPHNcqeLB.JkualxcHIOmaj1s0jrt_{font-weight:600}.XC1XQPSzu2WdWeUF43u8 .UVRreAw6epUGPHNcqeLB:last-of-type{border-bottom:none}", "",{"version":3,"sources":["webpack://./Client/Components/CardMenu/CardMenu.module.scss"],"names":[],"mappings":"AAKI,4CACI,YAAA,CACA,aAAA,CACA,6BAAA,CACA,cAAA,CACA,aAAA,CACA,4BAAA,CAEA,iEACI,eAAA,CAKJ,yDACI,kBAAA","sourcesContent":["@import \"~/Client/Styles/theme.scss\";\n@import \"../../Styles/theme.scss\";\r\n\r\n.cardMenu {\r\n\r\n    .item {\r\n        display: flex;\r\n        flex-flow: row;\r\n        justify-content: space-between;\r\n        cursor: pointer;\r\n        padding: 4px 0;\r\n        border-bottom: $theme-border-thin $theme-gray-light;\r\n\r\n        &.active {\r\n            font-weight: 600;\r\n/*            background-color: $theme-primary-light;\r\n            color: text-on($theme-primary-light);\r\n*/        }\r\n\r\n        &:last-of-type {\r\n            border-bottom: none;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardMenu": "XC1XQPSzu2WdWeUF43u8",
	"item": "UVRreAw6epUGPHNcqeLB",
	"active": "JkualxcHIOmaj1s0jrt_"
};
export default ___CSS_LOADER_EXPORT___;
