import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Input, Modal, ModalSize, Select, TextArea } from "@kaladel/react/Components";
import { useValidator } from "@kaladel/react/Hooks";
import { HttpStatusCode, Severity } from "@kaladel/react/Utilities";
import { AddressGet, AddressPut, SwaggerResponse } from "ApiClient/ApiClient";
import { useApp } from "Context/Resources";
import { useStaticData } from "Pages/Sites/StaticDataContext";
import * as React from "react";
import { ConfirmModal } from "./ConfirmModal";

interface AddressEditModalProps {
    address: AddressGet;
    onPut: (model: AddressPut) => Promise<SwaggerResponse<void>>;
    onDelete: () => Promise<SwaggerResponse<void>>;
    onClose: (dirty: boolean) => void;
}

export const AddressEditModal: React.FC<AddressEditModalProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();
    const { countries } = useStaticData()!;

    const [addressFreeText, setAddressFreeText] = React.useState<string>(props.address.addressFreeText ?? "");
    const [addressCity, setAddressCity] = React.useState<string>(props.address.city ?? "");
    const [addressState, setAddressState] = React.useState<string>(props.address.state ?? "");
    const [addressPostCode, setAddressPostCode] = React.useState<string>(props.address.postCode ?? "");
    const [addressCountryId, setAddressCountryId] = React.useState<string | undefined>(props.address.countryId?.toString() ?? undefined);

    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);

    const handleSaveClicked = async () => {
        if (!validator.canSubmit()) return;

        const model: AddressPut = {
            addressFreeText,
            city: addressCity,
            state: addressState,
            postCode: addressPostCode,
            countryId: !!addressCountryId ? parseInt(addressCountryId) : null
        };

        const response = await props.onPut(model);

        if (response.status === HttpStatusCode.Http204NoContent) {
            props.onClose(true);
        }
    };

    const handleDeleteConfirmed = async () => {
        const response = await props.onDelete();

        if (response.status === HttpStatusCode.Http204NoContent) {
            props.onClose(true);
        }
    };

    return (
        <Modal
            onClose={props.onClose}
            title={resource("Addresses-EditAddress")}
            size={ModalSize.Medium}
        >
            {confirmDelete && (
                <ConfirmModal
                    severity={Severity.Danger}
                    message={resource("Addresses-ConfirmDeleteAddress")}
                    positiveButton={resource("Button-Delete")}
                    negativeButton={resource("Button-Cancel")}
                    onPositiveClicked={() => handleDeleteConfirmed()}
                    onNegativeClicked={() => setConfirmDelete(false)}
                />
            )}

            <Form>

                <TextArea
                    id="addressFreeText"
                    label={resource("Addresses-Address-FreeText")}
                    value={addressFreeText}
                    onChange={(e) => setAddressFreeText(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                    rows={3}
                />

                <Input
                    id="addressCity"
                    label={resource("Addresses-Address-City")}
                    value={addressCity}
                    onChange={(e) => setAddressCity(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                />

                <Input
                    id="addressState"
                    label={resource("Addresses-Address-State")}
                    value={addressState}
                    onChange={(e) => setAddressState(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                />

                <Input
                    id="addressPostCode"
                    label={resource("Addresses-Address-PostCode")}
                    value={addressPostCode}
                    onChange={(e) => setAddressPostCode(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                />

                <Select
                    id="addressCountryId"
                    label={resource("Addresses-Address-Country")}
                    value={addressCountryId}
                    onChange={(e) => setAddressCountryId(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                    items={countries}
                    values={c => c.id.toString()}
                    descriptions={c => resource(`Country-${c.id}`)}
                />

                <div className="buttons right">
                    <div>
                        <Button
                            severity={Severity.Success}
                            onClick={() => handleSaveClicked()}
                            type="submit"
                        >
                            {resource("Button-Save")}
                        </Button>
                        <Button
                            severity={Severity.Primary}
                            onClick={() => props.onClose(false)}
                            type="button"
                        >
                            {resource("Button-Cancel")}
                        </Button>
                    </div>
                    <div>
                        <Button
                            severity={Severity.Danger}
                            onClick={() => setConfirmDelete(true)}
                            icon={faTrash}
                        >
                            {resource("Button-Delete")}
                        </Button>
                    </div>
                </div>

            </Form>
        </Modal>
    );
};