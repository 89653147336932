import { createContext, useContext } from "react";
import { AlertGet, CurrencyGet, SessionGet } from "ApiClient/ApiClient";

interface UserContextState {
    session?: SessionGet | null;
    refreshSession: () => void;
    alerts: AlertGet[];
    refreshAlerts: () => void;
    currencies: CurrencyGet[];
}

export const UserContext = createContext<UserContextState>({
    currencies: [],
    alerts: [],
    refreshAlerts: () => { },
    refreshSession: () => { }
});

export function useUser() {
    return useContext(UserContext);
};