export class Configuration {
  private static globalContext: IConfiguration;

  static get(): IConfiguration {
    return this.globalContext;
  }

  static async refresh() {
    var response = await fetch("/Configuration");
    var context = await response.json() as IConfiguration;
    this.setContext(context);
  }

  private static setContext(newContext: IConfiguration) {
    this.globalContext = newContext;
  }
}

export interface IConfiguration {
  apiUrl: string | null;
  appUrl: string | null;
  authUrl: string | null;
  authAppId: string | null;
}
