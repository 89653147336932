import { createContext, useContext } from "react";
import { Dictionary } from "Helpers/Dictionary";

interface AppContextState {
    resource: (key: string, variables?: Dictionary<string | number>) => string;
}

export const AppContext = createContext<AppContextState>({
    resource: () => {
        return "No resource finder has been implemented";
    }
});

export function useApp() {
    return useContext(AppContext);
};