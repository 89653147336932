// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aCaSaB01bP2c90C71D_o{display:flex;flex-direction:column;gap:10px}.oY6BBoekJUDJZ57krRrA{display:grid;grid-template-columns:repeat(auto-fit, minmax(300px, 400px));grid-gap:6px}.oY6BBoekJUDJZ57krRrA .dM9qG6i2WFgIeY1HZEi3{display:flex;flex-direction:row-reverse;gap:10px;justify-content:space-between}", "",{"version":3,"sources":["webpack://./Client/Pages/Profile/Profile.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAGJ,sBACI,YAAA,CACA,4DAAA,CACA,YAAA,CAEA,4CACI,YAAA,CACA,0BAAA,CACA,QAAA,CACA,6BAAA","sourcesContent":["@import \"~/Client/Styles/theme.scss\";\n.profile {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n}\r\n\r\n.addresses {\r\n    display: grid;\r\n    grid-template-columns: repeat(auto-fit, minmax(300px, 400px));\r\n    grid-gap: 6px;\r\n\r\n    .addressCard {\r\n        display: flex;\r\n        flex-direction: row-reverse;\r\n        gap: 10px;\r\n        justify-content: space-between;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": "aCaSaB01bP2c90C71D_o",
	"addresses": "oY6BBoekJUDJZ57krRrA",
	"addressCard": "dM9qG6i2WFgIeY1HZEi3"
};
export default ___CSS_LOADER_EXPORT___;
